<template>
  <div :class="{
    withdrawalsPage: true,
    withdrawals_info: withdrawalsActive===0,
    withdrawals_valid: withdrawalsActive===1,
    withdrawals_success: withdrawalsActive===3,
    withdrawals_fail: withdrawalsActive===2
    }">
    <header>
      <h2>收入提现</h2>
      <div class="breadcrumbs">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/salary' }">我的收入</el-breadcrumb-item>
          <el-breadcrumb-item>收入提现</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </header>
    <section class="step">
      <el-steps :active="withdrawalsActive" :process-status="status" finish-status="success">
        <el-step title="发起提现"></el-step>
        <el-step title="手机验证"></el-step>
        <el-step :title="result"></el-step>
      </el-steps>
    </section>
    <keep-alive>
      <component :is="isWho"></component>
    </keep-alive>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import withdrawalsLaunch from "@/views/withdrawals/components/Launch.vue";
import withdrawalsValid from "@/views/withdrawals/components/Valid.vue";
import withdrawalsSuccess from "@/views/withdrawals/components/Success.vue";
import withdrawalsFail from "@/views/withdrawals/components/Fail.vue";
export default {
  data() {
    return {
      status: "process",
      result: "完成提现",
    };
  },
  methods: {},
  computed: {
    ...mapGetters(["withdrawalsActive"]),
    isWho() {
      console.log(this.withdrawalsActive);
      if (this.withdrawalsActive === 0) {
        this.status = "process";
        this.result = "完成提现";
        return withdrawalsLaunch;
      } else if (this.withdrawalsActive === 1) {
        return withdrawalsValid;
      } else if (this.withdrawalsActive === 2) {
        this.status = "error";
        this.result = "提现失败";
        return withdrawalsFail;
      } else if (this.withdrawalsActive === 3) {
        return withdrawalsSuccess;
      }
    },
  },
  mounted() {
    this.$store.commit("withdrawals/SETACTIVE", 0);
  },
};
</script>

<style lang="less" scoped>
.withdrawalsPage {
  width: 1210px;
  height: 536px;
  background-color: white;
  margin: 36px auto;
  background-repeat: no-repeat;
  background-position: 95%;
  font-family: "Microsoft YaHei";
  &.withdrawals_info {
    background-image: url("../../assets/withdrawals_info.png");
  }
  &.withdrawals_valid {
    background-image: url("../../assets/withdrawals_valid.png");
  }
  &.withdrawals_success {
    background-image: url("../../assets/withdrawals_success.png");
  }
  &.withdrawals_fail {
    background-image: url("../../assets/withdrawals_fail.png");
  }
  h2 {
    height: 67px;
    padding-left: 62px;
    font-size: 18px;
    line-height: 67px;
    display: inline-block;
    vertical-align: baseline;
  }
  .breadcrumbs {
    display: inline-block;
    font-size: 14px;
    padding-left: 36px;
    font-weight: bold;
    vertical-align: middle;
  }
  section.step {
    height: 159px;
    padding: 36px 76px;
    .el-steps {
      width: 626px;
      /deep/ .el-step.is-horizontal {
        /deep/ .el-step__head {
          /deep/ .el-step__icon {
            width: 40px;
            height: 40px;
            background: #d0d0d0;
          }
          /deep/ .el-step__line {
            height: 5px;
            top: 20px;
            left: 52px;
            right: 12px;
            background-color: #d0d0d0;
          }
          &.is-process {
            color: white;
            .el-step__icon {
              background: #8493fa;
            }
          }
          &.is-success {
            color: white;
            border-color: #8493fa;
            .el-step__icon {
              background: #8493fa;
            }
            .el-step__line {
              background-color: #8493fa;
            }
          }
          &.is-wait {
            color: white;
            border-color: #d0d0d0;
          }
          &.is-process {
            border-color: #8493fa;
          }
          &.is-error {
            color: white;
            border-color: #f2c863;
            .el-step__icon {
              background: #f2c863;
            }
          }
        }
        /deep/ .el-step__title {
          margin-left: -8px;
          &.is-success {
            color: #333333;
          }
        }
      }
    }
  }
}
</style>