<template>
  <div class="withdrawals_ok">
    <div class="ok_content">
      <h2>提现成功</h2>
      <div>提现金额：<span class="ok_info">￥{{info.money}}</span>&nbsp;({{info.place}})</div>
      <div>提现账号：支付宝（{{info.account}}）</div>
      <div>积分余额：<span class="ok_info">{{info.result}}</span></div>
    </div>
    <el-button class="next_btn" @click="toRecord">查看记录</el-button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import api from "@/api/list";
export default {
  data() {
    return {
      info: {
        money: 0.00,
        place: "",
        account: "",
        result: "",
      },
    };
  },
  computed: {
      ...mapGetters(["withdrawalsToResult"]),
  },
  created() {
     let temp = this.withdrawalsToResult;
     this.info.money = temp.amount;
     this.info.place = temp.ocompanyName;
     this.info.account = temp.account;
     // this.info.result = temp.result;
     this.initOcompanyBalance();
  },
  methods: {
    toRecord() {
      this.$store.commit("withdrawals/SETACTIVE", 0);
      this.$router.push("/salary/record");
    },
    initOcompanyBalance(){
       api.getOcompanyBalance().then((res) => {
           if (res.status == "200" && res.data){
               this.ocompanyAmountList = res.data;
                  let totalBalance = 0;
                  if (res.data != null) {
                      this.ocompanyAmountList.forEach(function (obj) {
                          totalBalance += parseFloat(obj.balance);
                      });
                  }
               let balance = totalBalance.toFixed(2);
               this.info.result =balance;
           }
       });
    } ,
  },
};
</script>

<style lang="less" scoped>
.withdrawals_ok {
  position: relative;
  width: 680px;
  height: 282px;
  padding: 30px 137px;
  .ok_content {
    width: 375px;
    float: left;
    h2 {
      width: 100%;
      height: 54px;
      padding-left: 56px;
      font-size: 24px;
      font-family: "Microsoft YaHei";
      font-weight: bold;
      line-height: 54px;
      background-image: url("../../../assets/withdrawals_success_icon.png");
      background-repeat: no-repeat;
      background-position: left;
    }
    div {
      width: 100%;
      height: 38px;
      font-size: 14px;
      font-weight: bold;
      line-height: 38px;
      color: #b3b3b3;
      .ok_info {
        color: black;
      }
    }
  }
  .next_btn {
    position: absolute;
    top: 150px;
    left: 510px;
    width: 160px;
    color: white;
    background: #8493fa;
  }
}
</style>