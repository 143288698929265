<template>
  <div class="withdrawals_lose">
    <div class="lose_content">
      <h2>提现失败</h2>
      <div>提现金额：<span class="lose_money">￥{{info.money}}</span>&nbsp;({{info.place}})</div>
      <div>提现账号：{{info.account}}</div>
      <div>失败原因：<span class="lose_reason">{{info.result}}</span></div>
    </div>
    <el-button class="next_btn" @click="toRecord">查看记录</el-button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      info: {
        money: 0.00,
        place: "",
        account: "",
        result: "",
      },
    };
  },
  computed: {
      ...mapGetters(["withdrawalsToResult"]),
  },
  created() {
      let temp = this.withdrawalsToResult;
      this.info.money = temp.amount;
      this.info.place = temp.ocompanyName;
      this.info.account = temp.account;
      this.info.result = temp.message;
  },
  methods: {
    toRecord() {
      this.$store.commit("withdrawals/SETACTIVE", 0);
      this.$router.push("/salary/record");
    },
  },
};
</script>

<style lang="less" scoped>
.withdrawals_lose {
  position: relative;
  width: 680px;
  height: 282px;
  padding: 36px 137px;
  .lose_content {
    width: 375px;
    float: left;
    h2 {
      width: 100%;
      height: 54px;
      padding-left: 56px;
      font-size: 24px;
      font-family: "Microsoft YaHei";
      font-weight: bold;
      line-height: 54px;
      background-image: url("../../../assets/shibai_icon.png");
      background-repeat: no-repeat;
      background-position: left;
    }
    div {
      width: 100%;
      height: 38px;
      font-size: 14px;
      font-weight: bold;
      line-height: 38px;
      color: #b3b3b3;
      .lose_reason {
        color: #fe7816;
      }
      .lose_money {
        color: black;
      }
    }
  }
  .next_btn {
    position: absolute;
    top: 150px;
    left: 510px;
    width: 160px;
    color: white;
    background: #8493fa;
  }
}
</style>