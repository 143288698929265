<template>
  <section class="withdrawals_vali">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="0px" class="demo-ruleForm">
      <el-form-item label="" prop="mobile">
        <el-input v-model="ruleForm.mobile" placeholder="输入手机号" class="input_phone" :disabled="true"></el-input>
      </el-form-item>
      <el-form-item label="" prop="code">
        <el-input v-model="ruleForm.code" placeholder="输入验证码" class="input_code">
          <template slot="append">
            <el-link type="info" v-if="isOn">({{count}}秒)后重新发送</el-link>
            <el-link type="primary" @click="handleCode" v-else>发送验证码</el-link>
          </template>
        </el-input>
      </el-form-item>
      <div class="next">
        <el-button @click="toPre">上一步</el-button>
        <el-button @click="toNext">提交</el-button>
      </div>
    </el-form>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import api from "@/api/list";
export default {
  data() {
    return {
      ruleForm: {
         mobile:"",
         code: "",
         token:"",
         account:"",
         amount:"",
         ocompanyId:"",
         type: 1,
      },

      isOn: false,
      count: 60,

      toResult: {
        amount: "",
        ocompanyId: "",
        account: "",
        ocompanyName: "",
        reason: "",
      },

      rules: {
        // phone: [{ required: true, message: "请输入手机号码", trigger: "blur" }],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
    };
  },
  created() {
    this.initEmployeeAccount();
    let temp = this.withdrawalsToSecond;
    this.toResult = temp;
    this.toResult.account = temp.alipayValue;
    this.ruleForm.account = temp.alipayValue;
    this.ruleForm.amount = temp.amount;
    this.ruleForm.ocompanyId = temp.ocompanyId;
  },
  computed: {
    ...mapGetters(["withdrawalsToSecond", "withdrawalsToResult"]),
  },
  methods: {
    // 获取支付宝信息
    initEmployeeAccount() {
      api.getEmployeeAccount().then((res) => {
        if (res.status == "200" && res.data) {
          this.ruleForm.mobile = res.data.mobile;
        }
      });
    },
    //发送验证码按钮
    handleCode() {
      if (this.isOn == false) {
        api.sendUserVerifySms({ mobile: this.ruleForm.mobile }).then((res) => {
          if (res.status == "200") {
            this.ruleForm.token = res.data;
            console.log(this.ruleForm);
            this.isOn = true;
            let that = this;
            let interval = window.setInterval(function () {
              --that.count;
              if (that.count < 0) {
                that.isOn = false;
                that.count = 60;
                window.clearInterval(interval);
              }
            }, 1000);
          } else {
            this.$message({
              showClose: true,
              message: res.data.message,
              type: "error",
            });
          }
        });
      }
    },
    toPre() {
      this.$store.commit("withdrawals/SETACTIVE", 0);
    },
    //下一步
    toNext() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
            //校验手机验证码
            api.checkCode(this.ruleForm,{ method: "patch" }).then((res) => {
                if(res!==undefined) {
                    if (res.status == "200") {
                        api.checkAndCash(this.ruleForm,{ method: "patch" }).then((res) => {
                            if (res.status == "200") {
                                //成功页面
                                this.$store.commit("withdrawals/SETTORESULT", this.toResult);
                                this.$store.commit("withdrawals/SETACTIVE", 3);
                            } else {
                                //失败页面
                                this.toResult.reason = res.message;
                                this.$store.commit("withdrawals/SETTORESULT", this.toResult);
                                this.$store.commit("withdrawals/SETACTIVE", 2);
                            }
                        })
                    } else {
                        this.$message({
                            showClose: true,
                            message: res.message,
                            type: "error",
                        });
                        return false;
                    }
                }
            });
            // this.$store.commit("withdrawals/SETTORESULT", this.toResult);
            // console.log(this.toResult+"toResult");
            // if (new Date() % 2 === 0) {
            //     //成功
            //     this.$store.commit("withdrawals/SETACTIVE", 3);
            // } else {
            //     this.$store.commit("withdrawals/SETACTIVE", 2);
            // }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.withdrawals_vali {
  padding: 0 76px;
  position: relative;
  .next {
    width: 626px;
    text-align: right;
    button {
      width: 160px;
      color: white;
      background: #8493fa;
    }
  }
  /deep/ .el-input__inner {
    width: 626px;
    padding-left: 70px;
    background-repeat: no-repeat;
    background-position: 2%;
  }
  .input_code {
    width: 626px;
    /deep/ .el-input__inner {
      width: 476px;
      display: inline-block;
      background-image: url("../../../assets/yanzhen_icon.png");
      background-color: transparent;
    }
    /deep/.el-input-group__append{
      display: inline-block;
      width: 150px;
      height: 40px;
      line-height: 38px;      
      text-align: center;      
    }
  }
  .input_phone {
    width: 626px;
    /deep/ .el-input__inner {
      background-image: url("../../../assets/shouji_icon.png");
      background-color: transparent;
    }
  }
}
</style>