<template>
  <section class="withdrawals_launch">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="0px" class="demo-ruleForm">
      <el-form-item label="" prop="ocompanyId">
        <el-select v-model="ruleForm.ocompanyId" placeholder="选择公司" class="choose_fac" @change="ocompanyEvent" clearable>
          <el-option v-for="item in companyList" :key="item.ocompanyId" :label="item.ocompanyName" :value="item.ocompanyId">
          </el-option>
        </el-select>
      </el-form-item>
      <!--<el-form-item label="" prop="account">-->
        <!--<el-select v-model="ruleForm.alipayValue" placeholder="选择到账账号" class="choose_account">-->
          <!--<el-option v-for="item in ruleForm.accounts" :key="item.value" :label="item.label" :value="item.value">-->
          <!--</el-option>-->
        <!--</el-select>-->
      <!--</el-form-item>-->
      <el-form-item label="" prop="alipayValue">
        <el-input v-model="ruleForm.alipayValue" placeholder="请输入到款账号" class="choose_account" :disabled="true"></el-input>
      </el-form-item>
      <el-form-item label="" prop="money">
        <div class="input_bg" v-if = flag>可提现金额{{amount}}(元)</div>
        <el-input v-model="cashAmount" placeholder="输入提现金额" class="input_money"
                  @keyup.native="cashAmount = oninput(cashAmount,2)"></el-input>
      </el-form-item>
      <div class="next">
        <el-button @click="toNext">下一步</el-button>
      </div>
    </el-form>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import api from "@/api/list";
export default {
  data() {
    return {
      //人力公司列表
      companyList:[],

      cashAmount:"",//实际提现金额
      amount:0.00,//可提现金额

      // moneyTip: "可提现金额150(元)",
      flag :false,
      ruleForm: {
        ocompanyId: "",
        alipayValue: "",
        amount: 0.00,
        ocompanyName: "",
      },
      rules: {
        ocompanyId: [{ required: true, message: "请选择公司名称", trigger: "change" }],
        alipayValue: [
            { required: true, message: "请输入到账账号", trigger: "blur" },
        ],
        // money: [{ required: true, message: "请输入提现金额", trigger: "blur" }],
      },
    };
  },

  mounted() {
     this.initOcompanyBalance();
     this.initAccount();
  },
  methods: {
    toNext() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
            if(this.cashAmount == undefined || this.cashAmount == ''){
                this.$notify({
                    message: "提取金额不能为空!",
                    type: "error",
                    duration: 2000,
                });
                return false;
            }
            var reg = /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/;
            if (!reg.test(this.cashAmount)) {
                this.$notify({
                    message: "请输入合法的金额数字，最多两位小数!",
                    type: "error",
                    duration: 2000,
                });
                return false;
            }
            if(parseFloat(this.cashAmount) < 0.1){
                this.$notify({
                    message: "提取金额不能为小于0.1元!",
                    type: "error",
                    duration: 2000,
                });
                return false;
            }
            if(this.cashAmount > this.amount){
                this.$notify({
                    message: "提取金额超出可提现余额!",
                    type: "error",
                    duration: 2000,
                });
                return false;
            }
            if(this.ruleForm.alipayValue == null || this.ruleForm.alipayValue ==''){
                this.$notify({
                    message: "没有配置提取账号!",
                    type: "error",
                    duration: 2000,
                });
                return false;
            }
            this.ruleForm.amount=this.cashAmount;
            api.checkCashAmount(this.ruleForm,{ method: "patch" }).then((res) => {
                if (res.status == "200" ){
                    // 成功，下一页
                    this.$store.commit("withdrawals/SETTOSECOND", this.ruleForm);
                    this.$store.commit("withdrawals/SETACTIVE", 1);
                }else{
                    this.$notify({
                        message: res.message,
                        type: "error",
                        duration: 2000,
                    });
                    return false;
                }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    //获取当前用户账号余额信息
    initOcompanyBalance(){
      api.getOcompanyBalance().then((res) => {
         if (res.status == "200" && res.data){
             for(let temp of res.data){
                 if(temp.balance != 0){
                     this.companyList.push({
                         ocompanyId: temp.ocompanyId,
                         ocompanyName: temp.ocompanyName,
                         balance: temp.balance
                     });
                 }
             }
            // this.companyList=res.data;
         }
      });
    },
    // 获取支付宝信息
    initAccount(){
        api.getAccount().then((res) => {
            if (res.status == "200" && res.data){
                this.ruleForm.alipayValue=res.data[0].account;
            }
        });
    },
    //公司选择事件
    ocompanyEvent(id) {
        if (id) {
            for (let temp of this.companyList) {
                if (temp.ocompanyId === id) {
                    this.flag = true;
                    this.amount = temp.balance;
                    this.ruleForm.ocompanyName=temp.ocompanyName;
                }
            }
        }else{
            this.amount = "";
            this.flag = false;
        }
    },
      //金额输入限制
      oninput(num, limit) {
          var str = num
          var len1 = str.substr(0, 1)
          var len2 = str.substr(1, 1)
          if (str.length > 1 && len1 == 0 && len2 != ".") {
              str = str.substr(1, 1)
          }
          if (len1 == ".") {
              str = ""
          }
          if (str.indexOf(".") != -1) {
              var str_ = str.substr(str.indexOf(".") + 1)
              if (str_.indexOf(".") != -1) {
                  str = str.substr(0, str.indexOf(".") + str_.indexOf(".") + 1)
              }
          }
          str = str.replace(/[^\d^\.]+/g, '')
          if (limit / 1 === 1) {
              str = str.replace(/^\D*([0-9]\d*\.?\d{0,1})?.*$/,'$1')
          } else {
              str = str.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1')
          }
          return str
      },
  },
  computed: {
     ...mapGetters([
        "withdrawalsToSecond",
     ]),
  },
};
</script>

<style lang="less" scoped>
.withdrawals_launch {
  padding: 0 76px;
  position: relative;
  .input_bg {
    width: 626px;
    height: 40px;
    line-height: 40px;
    position: absolute;
    top: 0px;
    left: 0px;
    text-align: right;
    color: #9c9da0;
    font-size: 14px;
    padding-right: 32px;
  }
  .next {
    width: 626px;
    text-align: right;
    button {
      width: 160px;
      color: white;
      background: #8493fa;
    }
  }
  /deep/ .el-input__inner {
    width: 626px;
    padding-left: 70px;
    background-repeat: no-repeat;
    background-position: 2%;
  }
  .choose_fac {
    /deep/ .el-input__inner {
      background-image: url("../../../assets/gongsi_icon.png");
    }
  }
  .choose_account {
    /deep/ .el-input__inner {
      background-image: url("../../../assets/zhanghaoi_icon.png");
    }
  }
  .input_money {
    /deep/ .el-input__inner {
      background-image: url("../../../assets/jine_icon.png");
      background-color: transparent;
    }
  }
}
</style>